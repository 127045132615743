// bower:scss
// endbower

@import 'typo';
@import 'variables';
@import 'extras';
@import 'layout';
@import 'admin';

html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "franklin-gothic-urw",sans-serif;
  font-weight: normal;
  margin:0;
}


img {
  max-width: 100%;
  height: auto;
}

a {
  color: black;
  text-decoration: none;

  &.active,
  &:hover {
    text-decoration: underline;
  }
}

footer {
  //font-size: 0.95rem;
}

.mt {
  font-size: 1.5rem;
  text-transform: uppercase;
}


.main-nav {

  li {

    a {
      text-transform: uppercase;
      font-size: 1.15rem;
    }

  }
}


.middle, .righttxt {
  font-size: 1.25rem;
}

.works .righttxt {
  font-size: 1rem;
}

.bio .right {
  p:nth-child(1){
    margin-top: 0;
  }

  .p-credits, .embed {
    font-size: 0.85rem;
  }
}

.project {

  a {
    text-decoration: none;
    color: black;
  }

  img {
    transition: all 0.3s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
    // filter: grayscale(100%);
  }

  .title {
    font-weight: 400;
    // visibility: hidden;
  }

  &:hover {
    img {
      filter: grayscale(0%);
      opacity: 1;
    }
    .title {
      // visibility: visible;
    }
  }

}

.texts .middletxt a:nth-of-type(1){
  text-decoration: underline;
}

.middle a.active {
  text-decoration: underline;
}

.newsitem {

  max-width: 500px;
  a {
    color: black;
    text-decoration: none;
  }
}

.works {
  .middletxt {
    ul {
      padding: 0;
      margin: 0;
      ul {
        padding-left: 20px;
      }
    }
    li {
      list-style: none
    }
  }
}

.soon {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fef200;
  width: 100%;
  padding: 10px;
  text-align: center;
}

.righttxt .img {
  margin-bottom: 10px;
  .p-credits {
    font-size: 0.85rem;
  }
}


// Guide
.guide {
  background-color: $black;
  color: $white;

  a {
    color: $white;
  }

  header.header {
    position: fixed;
    left: 20px;
    top: 20px;
  }

  h1 {
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 0;
  }


  .item {
    display: inline-block;
    &:not(.tp) img {
      box-shadow: 1px 1px 10px #000000;
    }

    .nr {
      position: absolute;
    }
  }

  .item {
    position: absolute;
  }

  .i1 {
    top: 140px;
    left: 10%;
  }

  .i2 {
    right: 250px;
    top: 0;
  }

  .i3 {
    left: calc(50% - 100px);
    top: calc(50% - 250px);
    .nr {
      right: 55px;
      bottom: 20px;
    }
  }

  .i4 {
    position: fixed;
    bottom: -10px;
    left: 20px;
    .nr {
      right: 30px;
      bottom: 20px;
    }
  }

  .i5 {
    top: 600px;
    right: 0;
  }

  .i6 {
    top: 800px;
    left: 300px;
    margin-bottom: 40px;
  }

  iframe {
    max-width: 100%;
  }

  .legend {
    position: absolute;
    top: 500px;
    left: 250px;
  }

}


// contact
#mc_embed_signup {
  margin: 40px 0;
  h4 {
    margin-bottom: 20px;
  }
}
input {
  padding: 10px;
  border: 1px solid $black;
  border-bottom: 1px solid $black;
}
input[type=email]{
  width: 50%;
  font-family: "franklin-gothic-urw",sans-serif;
  font-size: 1rem;
}
input[type=submit]{
  text-transform: uppercase;
  font-family: "franklin-gothic-urw",sans-serif;
  font-size: 1rem;
  background-color: white;
  color: $black;
  &:hover {
    background-color: $black;
    color: white;
  }
}

@media all and (max-width: 768px) {

  .guide {

    .legend {
      top: 200px;
      left: -10px;
      max-width: 160px;
      padding-right: 10px;
      background-color: $black;
    }
    .item {
      position: absolute;
    }
    .i1 {
      // top: 0;
      left: auto;
      top:110px;
      right: 0px;
    }

    .i2 {
      right: auto;
      left: 10px;
      top: 550px;
    }

    .i3 {
      left: auto;
      right:0;
      top: 750px;
      .nr {
        right: 55px;
        bottom: 20px;
      }
    }

    .i4 {

      bottom: auto;
      top: 1100px;
      left: 50px;


      .nr {
        right: 30px;
        bottom: 20px;
      }
    }

    .i5 {
      // top: 0;
      top: 1400px;
      right: 0;
      .nr {
        left: 10px;
      }
    }

    .i6 {
      top: 1800px;
      left: 0;
      margin-bottom: 100px;
      .nr {
        left: 10px;
      }
    }
  }

}