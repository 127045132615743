.wrapper {
  padding: 20px;
  //display: flex;
  //justify-content: space-around;
}

.left {
  //width: calc(100%/7);
  width: 200px;
  position: fixed;
  top: 20px;
  left: 20px;
  // background-color: pink;
}

.middle {
  width: calc((100% - 200px)/2 - 120px);
  //max-width: 600px;
  position: fixed;
  left: 220px;
  top: 1px;
  bottom: 0;
  overflow: hidden;
  //background-color: yellow;

  .middletxt {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    // hide scrollbar
    // http://stackoverflow.com/questions/16670931/hide-scroll-bar-but-still-being-able-to-scroll
    padding-right: 17px;

    .mt {
      margin-top: 19px;
    }

    a {
      // display: block;
      color: black;
      margin: 5px 0 15px 0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    // return of the swallow needs some margin
    &>ul>li:last-child {
      margin-top: 20px;
    }
  }

  .description,
  .technical,
  .website {
    a {
      text-decoration: underline;
    }
  }
}

.right {
  max-width: 100%;
  width: calc((100% - 200px)/2 + 60px);
  float: right;
  padding-bottom: 40px;

  //margin-left: calc(100%/2 + 200px);
  // background-color: green;
  a {
    text-decoration: underline;
  }

}

/* LEFT */

.main-nav {
  margin-top: 15px;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    margin: 0;

    ul {
      margin-left: 10px;
    }

  }
}


footer {
  position: fixed;
  bottom: 15px;
  right: 20px;
}

.homebio {
  /*margin:25px 45px 0 0;
  display: inline-block;
  max-width: calc(50% - 49px);
  text-align: justify;*/
}

.sp {
  //display: none;

  margin-bottom: 10px;
  // position: absolute;
  // top:120px;
  // left:630px;
  // z-index: 2;
  // transform: rotate(90deg);
}


.project {
  margin-bottom: 30px;
}



.projectpage,
.newspage {
  h1 {
    margin-top: 90px;
    line-height: 1.5rem;
  }
}

.bio article {
  margin-top: 90px;
}

.newsholder {
  margin-top: 90px;
}

.bio .download {
  text-decoration: underline;
  margin-bottom: 15px;
}

.bio .sel-txt {
  margin-top: 60px;
  margin-bottom: 60px;

  a {
    display: block;
    margin-bottom: 10px;
  }
}

.bio .embed,
.contact .embed {
  margin-bottom: 20px;

  .fluid-width-video-wrapper {
    margin-bottom: 5px;
  }

}


/* INTRO */

.intro {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  top: 0;
  left: 0;
  text-align: center;
  z-index: 666;
  transition: all 0.3s cubic-bezier(0.445, 0.050, 0.550, 0.950);

  h1 {
    text-transform: uppercase;
    font-weight: 100;
    padding: 0;
    margin: 0;
    padding-top: 20%;
    font-size: 3rem;
  }

  .recent {
    font-size: 1.25rem;
    text-transform: uppercase;
  }
}

.closed {
  opacity: 0;
  z-index: -1;
}



.middletxt>.langselector {
  margin-top: 0;
}

.middletxt>.langselector~.langselector {
  margin-top: 15px;
}


.projectpage {
  .project {
    margin-bottom: 0;
  }

  .thumbs {}

  .thumb {
    display: inline-block;
  }
}

.news .middletxt a,
.texts .middletxt a,
.textdetailpage .middletxt a {
  display: block;
}

.fluid-width-video-wrapper {
  margin-bottom: 10px;
}

.quote {
  font-size: 1.5rem;
  font-style: italic;
  margin: 30px 0 60px 0;
}

blockquote {
  quotes: "“""”""‘""’";
}

blockquote:before {
  content: open-quote;
}

blockquote:after {
  content: close-quote;
}

.bio .quote {
  margin-top: 60px;
}

.contact .quote {
  margin-top: 0;
}


// print
.print .middle {
  width: calc(100% - 220px);

  .list {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0 60px 0;
  }

  .item {
    margin: 0 10px 10px 0;
    width: calc(100%/3 - 10px);

    .title {
      margin-bottom: 20px;
    }
  }
}

/* --- MEDIA QUERIES --- */


@media all and (max-width: 1024px) {}


@media all and (max-width: 965px) {

  .wrapper {
    padding: 0 20px;
  }

  .left,
  .middle,
  .right {
    position: relative;
    width: 100%;
    margin: 10px 0;
    padding: 0;
    left: 0;
  }

  .left {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .right {
    margin-bottom: 40px;
  }

  .main-nav li {
    display: inline-block;
    vertical-align: top;
  }

  .print .middle {
    width: calc(100% + 10px);

    .item {
      width: calc(100%/2 - 10px);
    }

  }


  .guide footer {
    display: none;
  }

}


@media all and (max-width: 800px) {}


@media all and (max-width: 500px) {

  .print .middle {
    width: calc(100% + 10px);

    .item {
      width: calc(100% - 10px);
    }

  }

}