/* custom edit buttons */

.edit-block {
  position: relative;
  
  a {
    color: $purple !important;
    position: relative;
    &:hover {
      color: $rain !important;

      &:after {
        content: 'edit';
        font-size: 0.65rem;
        position: absolute;
        left:18px;
      }
    }
  }
}


/* admin bar colors */
.admin_bar_wrapper {
  background-color: $purple !important;
}
